.ant-form.login-form {
  padding-top: 40px;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
}
.google-login-button{
  width: 100%;
  line-height: 11px;
}
