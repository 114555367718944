/* ----------- iPhone 4 and 4S ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {

}

/* Portrait */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
}

/* Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {

}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {
  
  .profile-user {
    display: none;
  }

  .ant-layout-content > .steps-header {
    background: #116cc5;
    padding: 1rem;
  }

  .steps-content {
    padding: 1rem;
  }

  .sml-credit > .sml-title,
  .sml-credit-result > .sml-title {
    padding-left: 1rem;
  }

  .sml-credit-cnt > .sml-monto,
  .sml-credit-cnt > .sml-cuotas,
  .sml-credit-cnt > .sml-seguros {
    padding: 0 1rem;
  }

  .sml-credit-result > .sml-credit-result-cnt {
    text-align: left;
    padding: 0 1rem;
  }

  .sml-credit-result-cnt .divider-result {
    border: 0;
  }

  .sml-end .title-cae,
  .sml-end .title-seg {
    color: #116cc5;
    padding-left: 1rem;
  }

  .sml-end .title-cae,
  .sml-end .title-seg {
    color: #116cc5;
    padding-left: 1rem;
  }

  .wrapper-divider-left {
    position: absolute;
    border-left: 0;
  }
  
  .wrapper-divider-right {
    position: absolute;
    border-right: 0;
  }

  .pi,
  .pi > .pi-cnt.ant-col-sm-24.ant-col-md-8 {
    margin-left: 0!important;
    margin-right: 0!important;
    padding-left: 0px!important;
    padding-right: 0px!important;
    margin-bottom: 2rem;
  }

  .pi > .pi-cnt-dc.ant-col-sm-24.ant-col-md-8,
  .pi > .pi-cnt-db.ant-col-sm-24.ant-col-md-8 {
    padding-left: 1rem!important;
    padding-right: 1rem!important;
  }

  .pi > .pi-title {
    padding-left: 1rem;
  }

  .ud > .ud-title {
    padding-left: 1rem;
  }

  .ud > .ud-file {
    margin: 1rem 0;
    padding-left: 1rem;
  }

  .rs > .rs-title {
    padding-left: 1rem;
  }

  .rs > .rs-cnt {
    text-align: left;
    padding: 0 1rem;
  }

  .rs-cnt .divider-result {
    border: 0;
  }

  .rs-end .title-cae,
  .rs-end .title-seg {
    color: #116cc5;
    padding-left: 1rem;
  }
}

/* Portrait */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
}

/* Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {

}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) { 

}

/* Portrait */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) { 

}

/* Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) { 

}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3) { 

}

/* Portrait */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 

}

/* Landscape */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) { 

}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 

}

/* Portrait */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 

}

/* Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) { 

}
