@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i&display=swap');

html, body {
  font-family: 'Open Sans', sans-serif;
  min-height: 100%;
}
  
body {
  min-height: 100%;
}

.ant-layout-content {
  background: #fff;
  display: flex;
  flex-flow: column nowrap;
}
  
.ant-layout {
  min-height: 100vh;
}

a {
  color: #3ca7ac;
}

button.ant-btn-link {
  color: #3ca7ac;
  padding-left: 0px;
}

a:hover, a:visited, a:focus, button.ant-btn-link:hover, button.ant-btn-link:focus, button.ant-btn-link:visited  {
  color: #278085;
  text-decoration: underline;
}

p, .ant-form-item-label > label {
  color: rgba(0, 0, 0, 0.65);
  font-size: 0.83rem;
}

b {
  font-weight: 600;
}

div.ant-modal-title {
  font-size: 17px;
  padding-right: 10px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.65);
}

/* buttons */

/* radio */

label.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled), label.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child, label.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #3ca7ac;
  border-color: #3ca7ac;
  box-shadow: -1px 0 0 0 #3ca7ac;
}

label.ant-radio-button-wrapper:hover {
  color: #3ca7ac;
}

/* primary */

.ant-btn-primary {
  background-color: #3ca7ac;
  border-color: #3ca7ac;
  font-weight: 500;
}

.ant-btn-primary:hover, .ant-btn-primary:active, .ant-btn-primary:focus {
  background-color: #32969b;
  border-color: #32969b;
}

button.green-button {
  border: 0px;
  color: #fff;
  background: #43A047;
  border-color: #43A047;
}

button.red-button {
  border: 0px;
  color: #fff;
  background: #D32F2F;
  border-color: #D32F2F;
}

.ant-btn-primary.red-button:hover:not([disabled]), .ant-btn-primary.red-button:visited, .ant-btn-primary.red-button:focus {
  border: 0px;
  color: #fff;
  background: #c62828;
  border-color: #c62828;
} 

button.green-button:hover, button.green-button:focus, button.green-button:active {
  border: 0px;
  color: #fff;
  background: #388e3c;
  border-color: #388e3c;
}

/* secondary */

.ant-btn-secondary {
  border: 1.2px solid #3ca7ac;
  color: #4e4e4e;
} 

.ant-btn-secondary:hover, .ant-btn-secondary:active, .ant-btn-secondary:focus {
  border: 1.2px solid #32969b;
  color: #4e4e4e;
  background: #f7f7f7;
}

button.green-button.ant-btn-secondary {
  border: 1.2px solid #7AA52A;
  color: #4e4e4e;
  background: #fff;
}

button.green-button.ant-btn-secondary:hover, button.green-button.ant-btn-secondary:focus, button.green-button.ant-btn-secondary:active {
  border: 1.2px solid #6d9622;
  color: #4e4e4e;
  background: #f7f7f7;
}

button.red-button.ant-btn-secondary { 
  border: 1.2px solid #C63F33;
  color: #4e4e4e;
  background: #fff;
}

button.red-button.ant-btn-secondary:focus, button.red-button.ant-btn-secondary:active, button.red-button.ant-btn-secondary:focus { 
  border: 1.2px solid rgb(167, 44, 33);
  color: #4e4e4e;
  background: #f7f7f7;
}

.help-button {
  border: none !important;
  color: #3ca7ad !important;
  background-color: transparent !important;
  font-size: 1.3rem;
  padding: 0px 5px;
}

.help-button i{
  vertical-align: sub;
}
