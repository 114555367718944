.modal-plexos .ant-tabs-bar.ant-tabs-top-bar div.ant-tabs-tab:nth-child(-n + 2) {
    visibility: visible !important;
}

.modal-plexos .ant-tabs-nav.ant-tabs-nav-animated {
    margin-left: -10px !important;
}

.modal-plexos div.ant-tabs-content.ant-tabs-top-content {
    padding: 0px;
}

div.modal-plexos h2 {
    text-align: left;
    font-size: 15px;
    font-weight: 600;
    color: #3ca7ac;
    border-top: none;
}

div.modal-plexos div.ant-collapse {
    margin-bottom: 20px;
}

div.ant-modal-wrap {
    overflow: auto!important;
}
