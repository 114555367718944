/* header */

div.add-modal .ant-modal-header .ant-modal-title {
    color: rgba(0, 0, 0, 0.65);
    font-weight: 600;
}

/* body */

div.add-modal div.ant-form-item-label {
    line-height: 18px;
}

div.add-modal.add-single .ant-modal-body span.ant-divider-inner-text {
    font-size: 16px;
    color: #3ca7ac;
    font-weight: 600;
}

div.add-modal .ant-col textarea.ant-input {
    margin-top: 3px;
}
