#work-request-list{
    margin-bottom: 30px;
}

#work-request-list .ant-pagination-item:hover,
#work-request-list .ant-pagination-item:focus,
#work-request-list .ant-pagination-item-active,
#work-request-list .ant-pagination-prev:hover,
#work-request-list .ant-pagination-next:hover .ant-pagination-item-link,
#work-request-list .ant-pagination-next:focus .ant-pagination-item-link,
#work-request-list .ant-pagination-prev:hover .ant-pagination-item-link,
#work-request-list .ant-pagination-prev:focus .ant-pagination-item-link,
#work-request-list .ant-select-selection:hover,
#work-request-list .ant-select-selection:focus,
#work-request-list input:hover,
#work-request-list input:focus
{
    border-color: #3ca7ac ;
}

#work-request-list .ant-pagination-item:hover a,
#work-request-list .ant-pagination-item:focus a,
#work-request-list .ant-pagination-item-active a,
#work-request-list .ant-pagination-next:hover .ant-pagination-item-link,
#work-request-list .ant-pagination-next:focus .ant-pagination-item-link,
#work-request-list .ant-pagination-prev:hover .ant-pagination-item-link,
#work-request-list .ant-pagination-prev:focus .ant-pagination-item-link
{
    color: #3ca7ac ;
}

#work-request-list .ant-spin-nested-loading{
    margin-top: 15px;
}

.batch-aproval-buttons{
    position: relative;
    float: left;
    z-index: 100;
}

.batch-aproval-buttons button{
    margin-right: 10px;
}
