div.affected-consumption div.ant-col {
    padding-right: 32px!important;
    display: flex;
    flex-flow: column;
}

div.affected-consumption div.ant-col.add-company {
    display: inherit;
    margin-top: 18px;
}

div.affected-consumption div.ant-col label {
    color: rgba(0, 0, 0, 0.65);
    font-size: 0.83rem;
    line-height: 18px;
    margin-bottom: 5px;
}

/* table */

.affected-column table .ant-table-thead > tr > th {
    padding: 5px 15px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 0.83rem;
    font-weight: 600;
    background: #ececec;
}

.affected-column table {
    margin-top: 15px;
}
