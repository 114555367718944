.ant-card.main-form {
    margin-bottom: 15px;
    margin-top: 10px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.03);
}

.ant-card.main-form .ant-card-body {
    padding: 18px 10px;
}

.ant-card.main-form .ant-card-head {
    min-height: inherit;
    padding-left: 15px;
}

.ant-card.main-form .ant-card-head-title {
    color: #3ca7ac;
    font-size: 1rem;
    padding: 5px;
}

div.ant-card.main-form div.ant-row.form-content div.ant-col {
    margin-bottom: 5px;
}

.ant-card.main-form .ant-form-inline .ant-row.form-content {
    border-bottom: 1px solid #f0f0f0;
}

.ant-card.main-form .ant-form-inline .form-buttons {
    margin-bottom: -10px;
    margin-top: 10px;
}

.ant-card.main-form .ant-form-inline .plp-buttons {
    margin-left: 10px;
    margin-bottom: -10px;
    margin-top: 10px;
}

.main-form .ant-form-inline .ant-form-item > .ant-form-item-label {
    line-height: inherit;
    display: flex;
    margin-bottom: -3px !important;
}

.form-buttons {
    float: right;
}

.form-buttons button {
    margin: 0px 10px;
}
