/* card body */

.ant-list-grid div.ant-col div.ant-list-item {
    margin-bottom: 15px;
}

div.ant-list-item.Rechazado .ant-card-body{
    border-left: 4px solid #d32f2f;
}

div.ant-list-item.Aprobado .ant-card-body{
    border-left: 4px solid #4caf50;
}

.work-request-card .ant-card-body {
    padding: 0px;
    border-left: 4px solid #FFB300;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.06);
}

.ant-card.ant-card-bordered {
    border: none;
}

/* status */

div.status-section {
    min-width: fit-content;
    max-width: fit-content;
    margin-left: -5px;
}

.status-section span.Pendiente {
    color: #ff8f00;
    font-weight: 600;
    padding: 10px 0px;
    font-size: 0.8rem;
    display: inline-block;
}

.status-section span.Aprobado {
    color: #4caf50;
    font-weight: 600;
    padding: 10px 0px;
    font-size: 0.8rem;
    display: inline-block;
}

.status-section span.Rechazado {
    color: #d32f2f;
    font-weight: 600;
    padding: 10px 0px;
    font-size: 0.8rem;
    display: inline-block;
}

/* card header */

div.ant-tabs-content .ant-card .work-request-card-header{
    background: #F5F5F5;
    padding-left: 20px;
}

.ant-row.work-request-card-header div.ant-col {
    min-width: fit-content;
    max-width: fit-content;
    margin-right: 20px;
}

.ant-row.work-request-card-header .empresa {
  margin-left: -10px;
}

/* card content */

div.work-request-card-content {
    margin-left: 20px;
}

div.work-request-card-content div.ant-col {
    margin-right: 10px;
}

.content-padding {
    padding: 10px 0px;
    margin-bottom: 0px;
}

.ant-card.work-request-card .work-request-card-content div.ant-typography {
    margin: 0px;
}

.work-request-card-content .ant-col.modifications {
    height: 60px;
    padding-top: 15px;
    float: right;
}

.work-request-card-content .ant-col.modifications button {
    margin-right: 0px;
}

.work-request-card-content .ant-col.modifications .ant-scroll-number-only > p {
    color: #fff;
}

/* card collapse details */

div.ant-collapse-borderless {
    background-color: #fff;
}

.work-request-card div.ant-collapse div.ant-collapse-item div.ant-collapse-header {
    padding: 6px 30px;
    color: #3ca7ac;
    width: 200px;
    text-decoration: underline;
    margin-bottom: 0px;
}

div.ant-list-item.Rechazado div.ant-collapse div.ant-collapse-item div.ant-collapse-header {
    margin-bottom: 10px;
}

.ant-collapse-borderless > .ant-collapse-item:last-child, .ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
    border: none;
}

div.ant-collapse-content-box div.ant-row {
    margin-left: 5px;
}

div.ant-collapse-content-box div.ant-row div.ant-col {
    max-width: fit-content;
    min-width: fit-content;
    margin-right: 25px;
}

div.ant-collapse-content-box div.ant-row div.ant-col p.work-description {
    max-width: 300px;
}

div.ant-collapse-content-box div.ant-row div.ant-col p.additional-comments, div.ant-collapse-content-box div.ant-row div.ant-col p.affected-dealers {
    max-width: 300px;
}

div.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
    border-top: 1.3px solid #eeeeee;
    margin-top: 20px;
}


/* card buttons and links */

.work-request-card a {
    text-decoration: underline;
    margin-right: 15px;
}

.work-request-card button {
    margin-right: 10px;
    flex-grow: 0;
}

.work-request-card-buttons {
    padding: 10px 0px 0px 0px;
    border-top: 1.3px solid #eeeeee;
}

.work-request-card-buttons div.buttons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    float: right;
    margin-bottom: 10px;
}

span.waiting {
    padding: 3px;
    margin-right: 15px;
}

/* Modifications */

