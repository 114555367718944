@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i&display=swap);
.ant-layout .header {
  background: #373A3D;
  padding: 0;
  height: 75px;
  display: flex;
  flex-flow: row;
}

img.logoCoordinador {
  height: 60px;
  margin: 5px 0px 0px 20px;
}

.ant-layout .header h1 {
  color: #fff;
  font-size: 1rem;
  line-height: 75px;
  font-weight: lighter;
}

.ant-menu.user-menu {
  border: none;
  background: #4d5256;
  color: #fff;
  height: 75px;
  padding: 10px;
}

.ant-menu.user-menu:hover, .ant-menu.user-menu li:hover, .ant-menu.user-menu div:hover, .ant-menu.user-menu ul:hover {
  border: none;
  color: #ebebeb;
}

.ant-menu-sub.ant-menu {
  border: none;
}

/* footer */

footer.ant-layout-footer {
  color: #ebebeb;
  background-color: #373A3D;
  font-size: 13px;
  min-height: 7em;
}

footer.ant-layout-footer span {
  display: block;
}

/* card body */

.ant-list-grid div.ant-col div.ant-list-item {
    margin-bottom: 15px;
}

div.ant-list-item.Rechazado .ant-card-body{
    border-left: 4px solid #d32f2f;
}

div.ant-list-item.Aprobado .ant-card-body{
    border-left: 4px solid #4caf50;
}

.work-request-card .ant-card-body {
    padding: 0px;
    border-left: 4px solid #FFB300;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.06);
}

.ant-card.ant-card-bordered {
    border: none;
}

/* status */

div.status-section {
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin-left: -5px;
}

.status-section span.Pendiente {
    color: #ff8f00;
    font-weight: 600;
    padding: 10px 0px;
    font-size: 0.8rem;
    display: inline-block;
}

.status-section span.Aprobado {
    color: #4caf50;
    font-weight: 600;
    padding: 10px 0px;
    font-size: 0.8rem;
    display: inline-block;
}

.status-section span.Rechazado {
    color: #d32f2f;
    font-weight: 600;
    padding: 10px 0px;
    font-size: 0.8rem;
    display: inline-block;
}

/* card header */

div.ant-tabs-content .ant-card .work-request-card-header{
    background: #F5F5F5;
    padding-left: 20px;
}

.ant-row.work-request-card-header div.ant-col {
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin-right: 20px;
}

.ant-row.work-request-card-header .empresa {
  margin-left: -10px;
}

/* card content */

div.work-request-card-content {
    margin-left: 20px;
}

div.work-request-card-content div.ant-col {
    margin-right: 10px;
}

.content-padding {
    padding: 10px 0px;
    margin-bottom: 0px;
}

.ant-card.work-request-card .work-request-card-content div.ant-typography {
    margin: 0px;
}

.work-request-card-content .ant-col.modifications {
    height: 60px;
    padding-top: 15px;
    float: right;
}

.work-request-card-content .ant-col.modifications button {
    margin-right: 0px;
}

.work-request-card-content .ant-col.modifications .ant-scroll-number-only > p {
    color: #fff;
}

/* card collapse details */

div.ant-collapse-borderless {
    background-color: #fff;
}

.work-request-card div.ant-collapse div.ant-collapse-item div.ant-collapse-header {
    padding: 6px 30px;
    color: #3ca7ac;
    width: 200px;
    text-decoration: underline;
    margin-bottom: 0px;
}

div.ant-list-item.Rechazado div.ant-collapse div.ant-collapse-item div.ant-collapse-header {
    margin-bottom: 10px;
}

.ant-collapse-borderless > .ant-collapse-item:last-child, .ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
    border: none;
}

div.ant-collapse-content-box div.ant-row {
    margin-left: 5px;
}

div.ant-collapse-content-box div.ant-row div.ant-col {
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    margin-right: 25px;
}

div.ant-collapse-content-box div.ant-row div.ant-col p.work-description {
    max-width: 300px;
}

div.ant-collapse-content-box div.ant-row div.ant-col p.additional-comments, div.ant-collapse-content-box div.ant-row div.ant-col p.affected-dealers {
    max-width: 300px;
}

div.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
    border-top: 1.3px solid #eeeeee;
    margin-top: 20px;
}


/* card buttons and links */

.work-request-card a {
    text-decoration: underline;
    margin-right: 15px;
}

.work-request-card button {
    margin-right: 10px;
    flex-grow: 0;
}

.work-request-card-buttons {
    padding: 10px 0px 0px 0px;
    border-top: 1.3px solid #eeeeee;
}

.work-request-card-buttons div.buttons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    float: right;
    margin-bottom: 10px;
}

span.waiting {
    padding: 3px;
    margin-right: 15px;
}

/* Modifications */



.ant-typography.next-value {
    color: #008000;
}

.ant-typography.previous-value {
    color: #000;
}

.modifications-modal .ant-modal-content .ant-modal-body {
    padding: 0px;
}

.modifications-modal .ant-modal-content .ant-pagination.ant-table-pagination {
    margin-right: 15px;
}

.modifications-modal .modification-button.ant-btn {
    margin-right: 10px;
}

.modifications-modal table tbody td {
    max-width: 400px;
    padding: 5px 10px;
}

.modifications-modal table td:first-child, .modifications-modal table th:first-child {
    padding-left: 25px;
}

#work-request-list{
    margin-bottom: 30px;
}

#work-request-list .ant-pagination-item:hover,
#work-request-list .ant-pagination-item:focus,
#work-request-list .ant-pagination-item-active,
#work-request-list .ant-pagination-prev:hover,
#work-request-list .ant-pagination-next:hover .ant-pagination-item-link,
#work-request-list .ant-pagination-next:focus .ant-pagination-item-link,
#work-request-list .ant-pagination-prev:hover .ant-pagination-item-link,
#work-request-list .ant-pagination-prev:focus .ant-pagination-item-link,
#work-request-list .ant-select-selection:hover,
#work-request-list .ant-select-selection:focus,
#work-request-list input:hover,
#work-request-list input:focus
{
    border-color: #3ca7ac ;
}

#work-request-list .ant-pagination-item:hover a,
#work-request-list .ant-pagination-item:focus a,
#work-request-list .ant-pagination-item-active a,
#work-request-list .ant-pagination-next:hover .ant-pagination-item-link,
#work-request-list .ant-pagination-next:focus .ant-pagination-item-link,
#work-request-list .ant-pagination-prev:hover .ant-pagination-item-link,
#work-request-list .ant-pagination-prev:focus .ant-pagination-item-link
{
    color: #3ca7ac ;
}

#work-request-list .ant-spin-nested-loading{
    margin-top: 15px;
}

.batch-aproval-buttons{
    position: relative;
    float: left;
    z-index: 100;
}

.batch-aproval-buttons button{
    margin-right: 10px;
}

div.affected-consumption div.ant-col {
    padding-right: 32px!important;
    display: flex;
    flex-flow: column;
}

div.affected-consumption div.ant-col.add-company {
    display: inherit;
    margin-top: 18px;
}

div.affected-consumption div.ant-col label {
    color: rgba(0, 0, 0, 0.65);
    font-size: 0.83rem;
    line-height: 18px;
    margin-bottom: 5px;
}

/* table */

.affected-column table .ant-table-thead > tr > th {
    padding: 5px 15px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 0.83rem;
    font-weight: 600;
    background: #ececec;
}

.affected-column table {
    margin-top: 15px;
}

/* header */

div.add-modal .ant-modal-header .ant-modal-title {
    color: rgba(0, 0, 0, 0.65);
    font-weight: 600;
}

/* body */

div.add-modal div.ant-form-item-label {
    line-height: 18px;
}

div.add-modal.add-single .ant-modal-body span.ant-divider-inner-text {
    font-size: 16px;
    color: #3ca7ac;
    font-weight: 600;
}

div.add-modal .ant-col textarea.ant-input {
    margin-top: 3px;
}

/* header */

div.add-modal-massive .ant-modal-header .ant-modal-title {
    color: rgba(0, 0, 0, 0.65);
    font-weight: 600;
}

/* body */

div.add-modal-massive .ant-modal-body .ant-card-body, div.add-modal-massive .ant-modal-body {
    padding: 0px;
}

div.add-modal-massive .ant-col textarea.ant-input {
    margin-top: 3px;
}

/* form */

div.add-modal-massive .massive-company {
    padding: 10px 30px;
    border-bottom: 2px solid #f1f1f1;
}

div.add-modal-massive .ant-card-body .ant-form.ant-form-horizontal .ant-row .ant-col-3:first-of-type  {
    min-width: 180px;
}

div.add-modal-massive .massive-request-form h2 {
    padding: 15px 0px 0px 30px;
    font-size: 17px;
    font-weight: 600;
    border-top: 1.5px solid #ececec;
    color: rgba(0, 0, 0, 0.65);
}

div.add-modal-massive .massive-request-form .massive-documents h2 {
    padding: 0px;
    border-top: none;
}

div.add-modal-massive div.massive-form-item {
    margin: 10px 0px;
    border-top: 1px solid #f1f1f1;
}

div.add-modal-massive div.massive-form-item h2 {
    text-align: left;
    font-size: 15px;
    font-weight: 600;
    color: #3ca7ac;
    padding: 10px 30px;
    border-top: none;
}

div.add-modal-massive div.massive-form-item h2:first-of-type {
    margin-bottom: 15px;
}

div.add-modal-massive div.massive-form-item div.ant-card {
    padding: 0px 30px;
}

div.add-modal-massive .massive-documents {
    padding: 10px 0px 5px 30px;
}

div.add-modal-massive .massive-documents .ant-col {
    margin: 0px 10px 0px 0px;
}


/* body */

div.add-modal-massive div.ant-form-item-label {
    line-height: 18px;
}

div.add-modal-massive .ant-modal-body span.ant-divider-inner-text {
    font-size: 0.9rem;
    color: rgba(0, 0, 0, 0.65);
    font-weight: 600;
}

div.add-modal-massive .ant-col textarea.ant-input {
    margin-top: 3px;
}

div.add-modal-massive .ant-calendar-picker {
    min-width: 100% !important
}

div.add-modal-massive .btn-hidden {
    display: none
}

.ant-card.main-form {
    margin-bottom: 15px;
    margin-top: 10px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.03);
}

.ant-card.main-form .ant-card-body {
    padding: 18px 10px;
}

.ant-card.main-form .ant-card-head {
    min-height: inherit;
    padding-left: 15px;
}

.ant-card.main-form .ant-card-head-title {
    color: #3ca7ac;
    font-size: 1rem;
    padding: 5px;
}

div.ant-card.main-form div.ant-row.form-content div.ant-col {
    margin-bottom: 5px;
}

.ant-card.main-form .ant-form-inline .ant-row.form-content {
    border-bottom: 1px solid #f0f0f0;
}

.ant-card.main-form .ant-form-inline .form-buttons {
    margin-bottom: -10px;
    margin-top: 10px;
}

.ant-card.main-form .ant-form-inline .plp-buttons {
    margin-left: 10px;
    margin-bottom: -10px;
    margin-top: 10px;
}

.main-form .ant-form-inline .ant-form-item > .ant-form-item-label {
    line-height: inherit;
    display: flex;
    margin-bottom: -3px !important;
}

.form-buttons {
    float: right;
}

.form-buttons button {
    margin: 0px 10px;
}

.modal-plexos .ant-tabs-bar.ant-tabs-top-bar div.ant-tabs-tab:nth-child(-n + 2) {
    visibility: visible !important;
}

.modal-plexos .ant-tabs-nav.ant-tabs-nav-animated {
    margin-left: -10px !important;
}

.modal-plexos div.ant-tabs-content.ant-tabs-top-content {
    padding: 0px;
}

div.modal-plexos h2 {
    text-align: left;
    font-size: 15px;
    font-weight: 600;
    color: #3ca7ac;
    border-top: none;
}

div.modal-plexos div.ant-collapse {
    margin-bottom: 20px;
}

div.ant-modal-wrap {
    overflow: auto!important;
}


.ant-tabs-bar.ant-tabs-top-bar {
    margin-bottom: 0px;
    padding-top: 10px;
    /* background: #f9f9f9; */
    border: none;
}

div.ant-tabs-content {
    padding: 10px 40px;
    background: #f9f9f9;
}

div.ant-tabs-extra-content {
    margin-right: 40px;
}

.ant-tabs-extra-content .operation:not(:last-child) {
    margin-right: 20px;
}

div.ant-tabs-nav .ant-tabs-tab-active, div.ant-tabs-nav .ant-tabs-tab:hover {
    color: #3ca7ac;
}

div.ant-tabs-ink-bar {
    background-color: #3ca7ac;
}

.ant-tabs-nav-wrap {
    margin-left: 20px;
}

/* toggle view */

.toggle-view {
    margin: 10px 0px;
}

/* button */

button.report-button {
    float: right;
    margin-bottom: 15px;
}

.ant-tabs-extra-content button{
    margin-right: 8px;
}

#filter-container{
    padding: 10px 40px;
    background-color: #f9f9f9;
}

.modalCancelBtn {
    margin-left: 16px;
}

h1.ant-modal-title {
    line-height: 22px;
    color: rgba(0, 0, 0, 0.65);
    font-weight: 600;
    margin-top: -10px;
    margin-bottom: 10px;
}



.ant-form.login-form {
  padding-top: 40px;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
}
.google-login-button{
  width: 100%;
  line-height: 11px;
}

.AuthLoader {
    flex: inherit;
    display: flex;
    min-height: -webkit-fill-available;
    min-height: -moz-available;
    height: -webkit-fill-available;
    height: -moz-available;
    height: stretch;
  }
  
  .ant-layout-content .AuthLoader {
    flex: inherit;
    display: flex;
    min-height: inherit;
  }
  
  div.steps-content .AuthLoader {
    background: #fff;
    height: inherit;
  }
  
  .AuthLoader > .Loader {
    margin: auto;
    align-self: center;
  }
  
  .AuthLoaderError {
    position: absolute;
    z-index: 4000;
    width: 100%;
    height: 75%;
    margin-top: 50px;
    text-align: center; 
  }
  
html, body {
  font-family: 'Open Sans', sans-serif;
  min-height: 100%;
}
  
body {
  min-height: 100%;
}

.ant-layout-content {
  background: #fff;
  display: flex;
  flex-flow: column nowrap;
}
  
.ant-layout {
  min-height: 100vh;
}

a {
  color: #3ca7ac;
}

button.ant-btn-link {
  color: #3ca7ac;
  padding-left: 0px;
}

a:hover, a:visited, a:focus, button.ant-btn-link:hover, button.ant-btn-link:focus, button.ant-btn-link:visited  {
  color: #278085;
  text-decoration: underline;
}

p, .ant-form-item-label > label {
  color: rgba(0, 0, 0, 0.65);
  font-size: 0.83rem;
}

b {
  font-weight: 600;
}

div.ant-modal-title {
  font-size: 17px;
  padding-right: 10px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.65);
}

/* buttons */

/* radio */

label.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled), label.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child, label.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #3ca7ac;
  border-color: #3ca7ac;
  box-shadow: -1px 0 0 0 #3ca7ac;
}

label.ant-radio-button-wrapper:hover {
  color: #3ca7ac;
}

/* primary */

.ant-btn-primary {
  background-color: #3ca7ac;
  border-color: #3ca7ac;
  font-weight: 500;
}

.ant-btn-primary:hover, .ant-btn-primary:active, .ant-btn-primary:focus {
  background-color: #32969b;
  border-color: #32969b;
}

button.green-button {
  border: 0px;
  color: #fff;
  background: #43A047;
  border-color: #43A047;
}

button.red-button {
  border: 0px;
  color: #fff;
  background: #D32F2F;
  border-color: #D32F2F;
}

.ant-btn-primary.red-button:hover:not([disabled]), .ant-btn-primary.red-button:visited, .ant-btn-primary.red-button:focus {
  border: 0px;
  color: #fff;
  background: #c62828;
  border-color: #c62828;
} 

button.green-button:hover, button.green-button:focus, button.green-button:active {
  border: 0px;
  color: #fff;
  background: #388e3c;
  border-color: #388e3c;
}

/* secondary */

.ant-btn-secondary {
  border: 1.2px solid #3ca7ac;
  color: #4e4e4e;
} 

.ant-btn-secondary:hover, .ant-btn-secondary:active, .ant-btn-secondary:focus {
  border: 1.2px solid #32969b;
  color: #4e4e4e;
  background: #f7f7f7;
}

button.green-button.ant-btn-secondary {
  border: 1.2px solid #7AA52A;
  color: #4e4e4e;
  background: #fff;
}

button.green-button.ant-btn-secondary:hover, button.green-button.ant-btn-secondary:focus, button.green-button.ant-btn-secondary:active {
  border: 1.2px solid #6d9622;
  color: #4e4e4e;
  background: #f7f7f7;
}

button.red-button.ant-btn-secondary { 
  border: 1.2px solid #C63F33;
  color: #4e4e4e;
  background: #fff;
}

button.red-button.ant-btn-secondary:focus, button.red-button.ant-btn-secondary:active, button.red-button.ant-btn-secondary:focus { 
  border: 1.2px solid rgb(167, 44, 33);
  color: #4e4e4e;
  background: #f7f7f7;
}

.help-button {
  border: none !important;
  color: #3ca7ad !important;
  background-color: transparent !important;
  font-size: 1.3rem;
  padding: 0px 5px;
}

.help-button i{
  vertical-align: sub;
}

/* ----------- iPhone 4 and 4S ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {

}

/* Portrait */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
}

/* Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {

}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {
  
  .profile-user {
    display: none;
  }

  .ant-layout-content > .steps-header {
    background: #116cc5;
    padding: 1rem;
  }

  .steps-content {
    padding: 1rem;
  }

  .sml-credit > .sml-title,
  .sml-credit-result > .sml-title {
    padding-left: 1rem;
  }

  .sml-credit-cnt > .sml-monto,
  .sml-credit-cnt > .sml-cuotas,
  .sml-credit-cnt > .sml-seguros {
    padding: 0 1rem;
  }

  .sml-credit-result > .sml-credit-result-cnt {
    text-align: left;
    padding: 0 1rem;
  }

  .sml-credit-result-cnt .divider-result {
    border: 0;
  }

  .sml-end .title-cae,
  .sml-end .title-seg {
    color: #116cc5;
    padding-left: 1rem;
  }

  .sml-end .title-cae,
  .sml-end .title-seg {
    color: #116cc5;
    padding-left: 1rem;
  }

  .wrapper-divider-left {
    position: absolute;
    border-left: 0;
  }
  
  .wrapper-divider-right {
    position: absolute;
    border-right: 0;
  }

  .pi,
  .pi > .pi-cnt.ant-col-sm-24.ant-col-md-8 {
    margin-left: 0!important;
    margin-right: 0!important;
    padding-left: 0px!important;
    padding-right: 0px!important;
    margin-bottom: 2rem;
  }

  .pi > .pi-cnt-dc.ant-col-sm-24.ant-col-md-8,
  .pi > .pi-cnt-db.ant-col-sm-24.ant-col-md-8 {
    padding-left: 1rem!important;
    padding-right: 1rem!important;
  }

  .pi > .pi-title {
    padding-left: 1rem;
  }

  .ud > .ud-title {
    padding-left: 1rem;
  }

  .ud > .ud-file {
    margin: 1rem 0;
    padding-left: 1rem;
  }

  .rs > .rs-title {
    padding-left: 1rem;
  }

  .rs > .rs-cnt {
    text-align: left;
    padding: 0 1rem;
  }

  .rs-cnt .divider-result {
    border: 0;
  }

  .rs-end .title-cae,
  .rs-end .title-seg {
    color: #116cc5;
    padding-left: 1rem;
  }
}

/* Portrait */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
}

/* Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {

}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) { 

}

/* Portrait */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) { 

}

/* Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) { 

}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3) { 

}

/* Portrait */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 

}

/* Landscape */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) { 

}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 

}

/* Portrait */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 

}

/* Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) { 

}

