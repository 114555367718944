.modalCancelBtn {
    margin-left: 16px;
}

h1.ant-modal-title {
    line-height: 22px;
    color: rgba(0, 0, 0, 0.65);
    font-weight: 600;
    margin-top: -10px;
    margin-bottom: 10px;
}
