
/* body */

div.add-modal-massive div.ant-form-item-label {
    line-height: 18px;
}

div.add-modal-massive .ant-modal-body span.ant-divider-inner-text {
    font-size: 0.9rem;
    color: rgba(0, 0, 0, 0.65);
    font-weight: 600;
}

div.add-modal-massive .ant-col textarea.ant-input {
    margin-top: 3px;
}

div.add-modal-massive .ant-calendar-picker {
    min-width: 100% !important
}

div.add-modal-massive .btn-hidden {
    display: none
}
