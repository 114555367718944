
.ant-tabs-bar.ant-tabs-top-bar {
    margin-bottom: 0px;
    padding-top: 10px;
    /* background: #f9f9f9; */
    border: none;
}

div.ant-tabs-content {
    padding: 10px 40px;
    background: #f9f9f9;
}

div.ant-tabs-extra-content {
    margin-right: 40px;
}

.ant-tabs-extra-content .operation:not(:last-child) {
    margin-right: 20px;
}

div.ant-tabs-nav .ant-tabs-tab-active, div.ant-tabs-nav .ant-tabs-tab:hover {
    color: #3ca7ac;
}

div.ant-tabs-ink-bar {
    background-color: #3ca7ac;
}

.ant-tabs-nav-wrap {
    margin-left: 20px;
}

/* toggle view */

.toggle-view {
    margin: 10px 0px;
}

/* button */

button.report-button {
    float: right;
    margin-bottom: 15px;
}

.ant-tabs-extra-content button{
    margin-right: 8px;
}

#filter-container{
    padding: 10px 40px;
    background-color: #f9f9f9;
}
