.AuthLoader {
    flex: inherit;
    display: flex;
    min-height: -webkit-fill-available;
    min-height: -moz-available;
    height: stretch;
  }
  
  .ant-layout-content .AuthLoader {
    flex: inherit;
    display: flex;
    min-height: inherit;
  }
  
  div.steps-content .AuthLoader {
    background: #fff;
    height: inherit;
  }
  
  .AuthLoader > .Loader {
    margin: auto;
    align-self: center;
  }
  
  .AuthLoaderError {
    position: absolute;
    z-index: 4000;
    width: 100%;
    height: 75%;
    margin-top: 50px;
    text-align: center; 
  }
  