/* header */

div.add-modal-massive .ant-modal-header .ant-modal-title {
    color: rgba(0, 0, 0, 0.65);
    font-weight: 600;
}

/* body */

div.add-modal-massive .ant-modal-body .ant-card-body, div.add-modal-massive .ant-modal-body {
    padding: 0px;
}

div.add-modal-massive .ant-col textarea.ant-input {
    margin-top: 3px;
}

/* form */

div.add-modal-massive .massive-company {
    padding: 10px 30px;
    border-bottom: 2px solid #f1f1f1;
}

div.add-modal-massive .ant-card-body .ant-form.ant-form-horizontal .ant-row .ant-col-3:first-of-type  {
    min-width: 180px;
}

div.add-modal-massive .massive-request-form h2 {
    padding: 15px 0px 0px 30px;
    font-size: 17px;
    font-weight: 600;
    border-top: 1.5px solid #ececec;
    color: rgba(0, 0, 0, 0.65);
}

div.add-modal-massive .massive-request-form .massive-documents h2 {
    padding: 0px;
    border-top: none;
}

div.add-modal-massive div.massive-form-item {
    margin: 10px 0px;
    border-top: 1px solid #f1f1f1;
}

div.add-modal-massive div.massive-form-item h2 {
    text-align: left;
    font-size: 15px;
    font-weight: 600;
    color: #3ca7ac;
    padding: 10px 30px;
    border-top: none;
}

div.add-modal-massive div.massive-form-item h2:first-of-type {
    margin-bottom: 15px;
}

div.add-modal-massive div.massive-form-item div.ant-card {
    padding: 0px 30px;
}

div.add-modal-massive .massive-documents {
    padding: 10px 0px 5px 30px;
}

div.add-modal-massive .massive-documents .ant-col {
    margin: 0px 10px 0px 0px;
}
