.ant-layout .header {
  background: #373A3D;
  padding: 0;
  height: 75px;
  display: flex;
  flex-flow: row;
}

img.logoCoordinador {
  height: 60px;
  margin: 5px 0px 0px 20px;
}

.ant-layout .header h1 {
  color: #fff;
  font-size: 1rem;
  line-height: 75px;
  font-weight: lighter;
}

.ant-menu.user-menu {
  border: none;
  background: #4d5256;
  color: #fff;
  height: 75px;
  padding: 10px;
}

.ant-menu.user-menu:hover, .ant-menu.user-menu li:hover, .ant-menu.user-menu div:hover, .ant-menu.user-menu ul:hover {
  border: none;
  color: #ebebeb;
}

.ant-menu-sub.ant-menu {
  border: none;
}

/* footer */

footer.ant-layout-footer {
  color: #ebebeb;
  background-color: #373A3D;
  font-size: 13px;
  min-height: 7em;
}

footer.ant-layout-footer span {
  display: block;
}
