.modifications-modal .ant-modal-content .ant-modal-body {
    padding: 0px;
}

.modifications-modal .ant-modal-content .ant-pagination.ant-table-pagination {
    margin-right: 15px;
}

.modifications-modal .modification-button.ant-btn {
    margin-right: 10px;
}

.modifications-modal table tbody td {
    max-width: 400px;
    padding: 5px 10px;
}

.modifications-modal table td:first-child, .modifications-modal table th:first-child {
    padding-left: 25px;
}
